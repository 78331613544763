import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Role } from "projects/shared/src/public-api";
import { Observable, of, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AppState, selectRoleInCommonState, selectUserInCommonState } from "../../app-state.index";
import { AppAuthService } from "../../kesher-shared/services/app-auth.service";
import { PermissionType } from "./permission-type.enum";



@Injectable()
export class PermissionsService {

    private _roleSubscription: Subscription;
    private _currentRole: Role;
    private _permissionsRoles: { permissionType: PermissionType, roles: Role[] }[] = [
        // { permissionType: PermissionType.AUTH_BANK_PAGE, roles: [Role.KesherUser] },
        { permissionType: PermissionType.MANAGMENT_MODULE, roles: [Role.KesherUser] },
        { permissionType: PermissionType.EDIT_PARENT_COMPANY, roles: [Role.KesherUser] },
        { permissionType: PermissionType.CUSTOMERS_PAGE, roles: [Role.KesherUser] },
        { permissionType: PermissionType.EDIT_KESHER_SERVICES, roles: [Role.KesherUser] },
        { permissionType: PermissionType.MOBILES, roles: [Role.KesherUser] },
        { permissionType: PermissionType.PROACTIVE_BROADCAST, roles: [Role.KesherUser] },
        { permissionType: PermissionType.MANAGE_LABELS, roles: [Role.KesherUser, Role.CompanyManager] },
        { permissionType: PermissionType.TECHNICIAN, roles: [Role.Technician] },
        { permissionType: PermissionType.PAYMENT_SUMMERY, roles: [Role.KesherUser] }

    ]

    constructor(private store: Store<AppState>, private authService: AppAuthService) {
        this._roleSubscription = store.select(selectRoleInCommonState).subscribe((role: any) => {
            this._currentRole = role;
        })
    }


    permissionExists(permissionType: PermissionType): Observable<boolean> {
        let permission = this._permissionsRoles.filter(p => p.permissionType === permissionType)[0];
        if (this._currentRole) {
            return of(this._currentRole && permission && permission.roles.filter(r => r === this._currentRole).length > 0);

        } else {
            if (this.authService.isLoggedOn) {
                return this.authService.getOrLoadUserInfo().pipe(map(response => {
                    this._currentRole = response.role;
                    return this._currentRole && permission && permission.roles.filter(r => r === this._currentRole).length > 0;
                }));
            } else {
                return of(false);
            }
        }
    }

    getRequiredRoles(permissionType: PermissionType): Role[] {
        let permission = this._permissionsRoles.filter(p => p.permissionType === permissionType)[0];
        return permission ? permission.roles : [];
    }

    ngOnDestroy() {
        if (this._roleSubscription) {
            this._roleSubscription.unsubscribe();
        }
    }
    isKesherAdminRole() {
        return this._currentRole == Role.KesherUser;
    }

    getArieUser(): boolean {
        let result: boolean = false;

        this.store.select(selectUserInCommonState).pipe(
            map(res => {
                return (res.identity == 5000 || res.identity == 328 || res.identity == 6378||res.identity == 278);
            }),
        ).subscribe(value => {
            result = value;
        });

        return result;
    }


}