import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdvMessageService } from 'shared/src/common';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent implements OnInit {

  @ViewChild('signatureCanvas') canvasRef!: ElementRef<HTMLCanvasElement>;
  @Output() signatureSaved = new EventEmitter<string | null>();

  @Input() showSaveButton: boolean = false;
  signaturePad!: SignaturePad;
  @Input() smaller: boolean=true;
  constructor(private messageService: AdvMessageService, private cdr: ChangeDetectorRef

  ) { }
  ngOnInit(): void { }

  ngAfterViewInit(): void {
     const canvas = this.canvasRef.nativeElement;
    // canvas.width = this.width;
    // canvas.height = this.height;
    this.signaturePad = new SignaturePad(canvas, {
      penColor: '#009',
      minWidth: 0.6
    });
   
  }

  clear(): void {
    this.signaturePad.clear();
    if (this.showSaveButton == true)
      this.signatureSaved.emit(null);
    else
      return null;
  }

  save() {
    if (this.signaturePad?.isEmpty()) {
      this.signedFullSignatureError()
    }
    const savedImage = this.signaturePad.toDataURL('image/png');
    if (savedImage.length < 3000) {
      this.signedFullSignatureError()
      return
    }
    if (this.showSaveButton == true)
      this.signatureSaved.emit(savedImage);
    else
      return savedImage;
  }

  signedFullSignatureError() {
    this.messageService.errorMessage('חתום חתימה מלאה', 5000, true);
    if (this.showSaveButton == true)
    {
      this.signatureSaved.emit(null);
      return;
    }     
    else
      return null;
  }

}
