import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, ColumnApi, GridApi, IServerSideGetRowsParams, Module } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, LOCALE_ID, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { CustomDateFilterComponent } from '../framework-components/components/custom-date-filter.component';
import { CustomListFilterComponent } from '../framework-components/components/custom-list-filter.component';
import { CustomDateFloatFilterComponent } from '../framework-components/filters/custom-date-float-filter.component';
import { NumberFloatingFilterComponent } from '../framework-components/filters/number-floating-filter.component';
import { BtnCellRenderer } from '../framework-components/renderers/button-cell-renderer.component';
import { CheckBoxIconRenderer } from '../framework-components/renderers/checkbox-renderer.component';
import { CustomNoRowsOverlay } from '../framework-components/renderers/customNoRowsOverlay';
import { DetailCellRenderer } from '../framework-components/renderers/detail-cell-renderer';
import { EnumTranslateColumnComponent } from '../framework-components/renderers/enum-translate-column.component';
import { FormGroupCellRenderer } from '../framework-components/renderers/form-group-cell-renderer.component';
import { MasterDetailsRendererComponent } from '../framework-components/renderers/master-details-renderer.component';
import { PipeRendererComponent } from '../framework-components/renderers/pipe-renderer.component';
import { TemplateRendererComponent } from '../framework-components/renderers/template-renderer.component';
import { ColumnHeaderTemplateComponent } from '../framework-components/templates/column-header-template.component';
import { FirstColumnTemplateComponent } from '../framework-components/templates/first-column-template.component';
import { QuickScanToolPanel } from '../framework-components/tool-panels/quick-sacn-tool-panel.component';
import { AG_GRID_LOCALE_HE } from '../grid-localization/ag-grid-local-he';
import { ButtonType, CellStyle, ColumnType, CompareType, CustomButton, DataLoadType, defualtColumnOptions, defualtGridOptions, FieldType, FilterMode, FilterType, globalSideBar, GridOptions, loadDataParameter, newButton, RowSelectionType, SortType, TemplateParams, ValueType } from '../grid-types';


// import { MasterDetailModule } from '@ag-grid-enterprise/master-detail' ;
// import { SideBarModule} from '@ag-grid-enterprise/side-bar';
@Component({
    selector: 'ag-grid-dynamic',
    templateUrl: './ag-grid-dynamic.component.html',
    styleUrls: ['./ag-grid-dynamic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})


export class AgGridDynamicComponent implements OnInit {

    constructor(private elRef: ElementRef, private translate: TranslateService,
        private _cdr: ChangeDetectorRef, @Inject(LOCALE_ID) public locale: string,
        // private store: Store<CommonState>
    ) {
        this.setGridLocalization();
        this.setDefualtGridOptions();
    }
    //public modules: Module[] = [ServerSideRowModelModule,ColumnsToolPanelModule,MasterDetailModule,SideBarModule];
    public modules: Module[] = [ServerSideRowModelModule, ExcelExportModule, ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule, MasterDetailModule, MenuModule, RichSelectModule];


    @Input() anotherSearch: TemplateRef<any>;


    //readonly VIRTUAL_SCROLLING_GRID_PROPERTIES = { cacheBlockSize: 2000, maxBlocksInCache: 5 };
    @ViewChild("agGrid") agGrid: AgGridAngular;
    @ViewChild("saveChangesButton") saveChangesButton: any;
    @ViewChild("cancelChangesButton") cancelChangesButton: any;
    //#region Private Members
    private pageName: string = "AgGridDynamicComponent";
    private subscriptions: Subscription = new Subscription();
    private filterMode = FilterMode.hidden;
    private buttons: CustomButton[];
    private dataSourceWasInitialized = false;
    //#endregion

    //#region  Public Members
    disabledDelete: boolean = true;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    totalCount: number;
    gridOptions: any = {};
    getContextMenuItems: any;
    sideBar = globalSideBar;
    formGroup: FormGroup = new FormGroup({});
    formArray: FormArray = new FormArray([]);
    onLoading: boolean = false;
    onViewLoading: boolean = false;
    displayRowDetails: boolean = false;
    moreDetails: boolean = false;
    aClickAccurred: number = 0;
    toolPanelAlreadyOpened: boolean;

    public getRowId: any = (params: any) => {
        return params.data.id;//אולי צריך לדאוג לשלוח את השדה Id בצורה כלשהי
    };
    public rowSelection = 'single';
    public rowModelType: 'clientSide' | 'infinite' | 'viewport' | 'serverSide' =
        'serverSide';

    public rowData!: any[];
    // editableArray: Map<string, boolean>=new Map<string,boolean>();;

    set selectedStatus(val) {
        if (this.options.status?.selectedStatus != val) {
            this.options.status.selectedStatus = val;
            this.setDataSource();
            this.gridApi.deselectAll();
        }
    }
    get selectedStatus() { return this.options?.status?.selectedStatus };

    _freeSearchText: string
    set freeSearchText(val) {
        this._freeSearchText = val;
        this.setDataSource();
    }
    get freeSearchText() { return this._freeSearchText }

    _searchCreditNumberText: string
    set searchCreditNumberText(val) {
        this._searchCreditNumberText = val;
        this.setDataSource();
    }
    get searchCreditNumberText() { return this._searchCreditNumberText }
    //#endregion

    //#region @Input Members  
    @Input() sumHeaderText: string;
    @Input() gridTitle: string = null;
    @Input() note = '';
    private _getRowData: () => Observable<any>;
    @Input() set getRowData(value: () => Observable<any>) {
        if (!this._getRowData) {
            this._getRowData = value;
            this.setDataSource();
        }
    }
    get getRowData() { return this._getRowData };

    private _options: GridOptions;
    @Input() set options(options: GridOptions) {
        this._options = options;
        if (options) {
            // if(this.needsFilter()) {
            //     options.buttons.push({ buttonType: ButtonType.FilterDisplay, label: this.setFilterButtonLabel(), icon: null, displayOptions: ButtonDisplayOption.always, enableOptions: ButtonEnableOption.always, onClick: this.handlingRowFilter.bind(this), tooltip: ""});
            // }
            if (!options.sortable) {
                this.columns = this.columns?.map(col => {
                    col.sortable = false;
                    return col;
                })
            }

            this.setGridSidebar(options);

            if (options.serverSideStoreType != "full") {
                this.getRowId = null
            }
            this.buttons = options.buttons;
            this.setGridType();
            this.setCommonOptions();
            if (this.columns) {
                this._columns = this.setSelectionColumn(this.columns);
                this._columns = this.setSaveAndCancelColumns(this.columns);
            }

            this.getContextMenuItems = (params) => {
                let baseOptions = options.contextMenu(params) ?? [];

                if (baseOptions.length > 0 && (options.displayRowDetailsMenu || options.displayMoreDetailsMenu))
                    baseOptions.push('separator');

                if (options.displayMoreDetailsMenu)
                    baseOptions.push({
                        name: "פרטים נוספים",
                        action: () => this.viewRowDetails(params.node.data, true),
                        icon: '<i class="pi pi-plus"></i>'
                    });

                if (options.displayRowDetailsMenu)
                    baseOptions.push({
                        name: this.getTranslation('GENERAL.SHOW') + ' ' + this.getTranslation('GRID.COLUMNS.ROW_DETAILS'),
                        action: () => this.viewRowDetails(params.node.data, false),
                        icon: '<i class="pi pi-ellipsis-h"></i>'
                    });

                return baseOptions;
            };

            if (options.fitColumns && this.gridApi) this.gridApi.sizeColumnsToFit();
        }
    }
    get options() { return this._options };

    private _columns: any[];
    @Input() set columns(columns: any[]) {
        this._columns = columns ? this.setColumns(columns) : null;
    }
    get columns() { return this._columns }
    //#endregion

    //#region @Output Members
    @Output() rowDoubleClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowDoubleClickedWithRowData: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() quickScanOpened: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellEditingStopped: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowEditingStopped: EventEmitter<any> = new EventEmitter<any>();
    //#endregion

    //#region @Input/@Output Members-two binding
    private _loadDataParameters: loadDataParameter
    @Input() set loadDataParameters(data: loadDataParameter) {
        if (data) {
            this._loadDataParameters = data;
            this.loadDataParametersChange.emit(data);
        }
    }
    get loadDataParameters() { return this._loadDataParameters }
    @Output() loadDataParametersChange: EventEmitter<loadDataParameter> = new EventEmitter<loadDataParameter>();

    private _selectedData: any;
    @Input() set selectedData(data: any) {
        if (!data) {
            this.gridOptions.api?.deselectAll();
        }
        if (data && data != this.selectedData) {
            this._selectedData = data;
            this.selectedDataChange.emit(data);
        }
    }
    get selectedData() { return this._selectedData }
    @Output() selectedDataChange: EventEmitter<any> = new EventEmitter<any>();

    /** Injected header components */
    @Input() subTitle: TemplateRef<any>;
    @Input() summeryTable: TemplateRef<any>;
    @Input() project: TemplateRef<any>;
    @Input() calander: TemplateRef<any>;
    @Input() subStatusMenu: TemplateRef<any>;
    @Input() leftSubStatusMenu: TemplateRef<any>;
    @Input() searchText: string;
    @Input() isSummeryTable: boolean;
    @Input() headerMoreInformation: any;
    @Input() editType: any;



    //#endregion

    //#region Angular lifecycle Methods
    ngOnInit(): void {
    }
    ngAfterViewInit() {
        if (this.dataSourceWasInitialized) {
            return;
        }
    }
    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
    //#endregion

    //#region Events Methods
    dropdownfilterChange(event) {

        this.setDataSource();

    }

    newRowAdded() {
        this.setDataSource();
    }

    getTableColumnsCount(): any[] {
        return new Array(this.columns?.length > 6 ? 6 : this.columns?.length);
    }
    customButtonClick(button: CustomButton) {

        if (button.buttonType == ButtonType.FilterDisplay || button.buttonType == ButtonType.New) {
            button.onClick();
        }
        //else if (button.buttonType == ButtonType.Export) {
        //   this.excelExport();
        //}
        else {
            if (button && button.onClick)
                button.onClick()?.subscribe(() => {
                    this.setDataSource();
                });
        }

    }


    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.closeToolPanel();
        this.listenToSidebarOpen();

        if (this.options.saveTableState) {
            this.gridColumnApi.applyColumnState({
                state: this.getState(),
                applyOrder: true,
            });
        }

        if (this.options.fitColumns) {
            this.gridApi.sizeColumnsToFit();
        }
        this.gridOptions.columnApi.getAllColumns().forEach((column) => {
            if (column.userProvidedColDef.compareType) {
                const filterComponent = this.gridOptions.api.getFilterInstance(column.colId);
                filterComponent.setModel({ type: CompareType[column.userProvidedColDef.compareType] });
            }
        });


    }




    autoSizeAll(skipHeader) {
        const allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });

        this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    refreshGridOptions() {
        this.options = { ...this.options };
    }
    refreshTableColumns() {
        let columns = this.setColumns(this.gridApi?.getColumnDefs())?.filter(column => column.headerName != "#");
        if (columns) {
            columns = this.setSelectionColumn(columns);
            columns = this.setSaveAndCancelColumns(this.columns);
            this.gridApi.setColumnDefs(columns);
        }
    }

    refreshColumns(columnsNames: Array<string>) {
        this.gridApi.refreshCells({ columns: columnsNames, force: true });
    }

    updateView() {
        this.gridApi.refreshServerSideStore({});
    }

    // Updates one row on table, than can refresh view only:
    updateRow(row: any, doNotRefresh = false) {
        var updatedRows = [];
        if (row) {
            this.gridApi.forEachNode(node => {
                if (node.data.id == row.data.id) {
                    node.setData(row.data);
                    updatedRows.push(row);
                }
            });
        }
        if (!doNotRefresh) {
            this.gridApi.refreshVirtualPageCache();
        }
        this.selectRow(row);
    }

    // Updates multiple rows on table, than refresh view only:
    updateMultipleRows(rows) {
        this.gridApi.forEachNode(node => {
            let row = rows.find(r => r.id == node.data?.id);
            if (row) {
                node.setData(row);
            }
        });
        this.gridApi.refreshServerSideStore({});
    }

    removeRowFromViewOnly(row: any) {
        //this.gridApi.applyServerSideTransaction({ remove: row });
        this.gridApi.refreshServerSideStore({ purge: false });
    }

    navigateToNextCell(params: any) {
        if (params && params.nextCellPosition)
            this.selectRowByIndex(params.nextCellPosition.rowIndex.toString());
        if (this.options.onNavigateToNextCell) {
            let data = this.getRowByRowIndex(params.nextCellPosition.rowIndex.toString())
            this.options.onNavigateToNextCell(data)
        }
        return params.nextCellPosition;

    }

    getRowByRowIndex(index: string) {
        return this.gridApi.getRowNode(index);
    }

    getDisplayedRowAtIndex(index: number) {
        return this.gridApi.getDisplayedRowAtIndex(index);
    }

    selectRowByIndex(index: number) {
        this.selectRow({ node: { rowIndex: index } });
    }

    selectRowById(id: number) {
        this.gridOptions.api.deselectAll();
        this.gridOptions.api.forEachNode(node => {
            if (id == node.data?.id) node.setSelected(true)
        })
    }
    refreshData() {
        if (this.gridApi) {
            this.gridApi.refreshServerSideStore({
                route: null,//refresh from top level 
                purge: false,//remove old data just after new data is recived
            });
        }
    }
    // refresh grid without load data from server:
    refreshViewOnly() {
        this.onViewLoading = true;
        this.gridApi.refreshServerSideStore({
            route: null,//refresh from top level 
            purge: false,//remove old data just after new data is recived
        });
    }
    collapseMasterDetails(id: any = null) {
        this.gridApi.forEachNode((node) => {
            if (node.data.id != id || id == null) {
                node.expanded = false;
            }
        });
    }
    selectAllRows() {
        this.deselectAllRows();
        this.gridApi.forEachNode((node) => {
            node.setSelected(true)
        });
    }
    deselectAllRows() {
        this.gridOptions.api.deselectAll();
        this._cdr.markForCheck();
    }
    //#endregion
    //#region Private 
    private setDataSource() {
        this.dataSourceWasInitialized = true;
        switch (this.options.gridType) {
            case DataLoadType.server:
                var datasource = {
                    getRows: (params: IServerSideGetRowsParams) => {
                        // Show preloader when data is loading.
                        // Do not show preloader on paginating, or when the view refreshed without loading data from server:
                        if (!this.gridApi.isToolPanelShowing() && !params.request.startRow && !this.onViewLoading) this.onLoading = true;
                        this.setLoadDataParameters(params.request);
                        this.totalCount = null;
                        this.gridOptions.rowData = null;
                        this.gridOptions.api!.hideOverlay();
                        this.getRowData().subscribe(data => {
                            data = data == null ? { totalCount: 0, entities: [] } : data;
                            this.totalCount = data.totalCount;
                            if (this.totalCount == 0 && (!this.loadDataParameters || !this.loadDataParameters.filter || this.loadDataParameters.filter.length == 0)) {
                                this.gridOptions.api!.showNoRowsOverlay();
                            }

                            this._cdr.markForCheck();
                            params.success({ rowData: data.entities, rowCount: this.totalCount });
                            this.gridOptions.rowData = data.entities;
                            if (this.options.selectFirstRow) {
                                if (data.selectedData) this.selectedData = data.selectedData;
                                if (this.selectedData?.length)
                                    this.selectRowById(this.options.rowSelectionType == RowSelectionType.single ? this.selectedData.id : this.selectedData[0].id)
                                else
                                    this.selectRowByIndex(0);
                            }

                            if (this.options.selectAllRows) {
                                this.selectAllRows()
                            }
                            this.onLoading = false;
                            this.onViewLoading = false;
                        }, () => {
                            params.fail();
                            this._cdr.markForCheck();
                        });
                    }
                };
                setTimeout(() => {
                    this.gridOptions.api?.setServerSideDatasource(datasource);
                }, 1);
                break;
        }
    }

    private setColumns(columns: any) {
        if (!columns) {
            return;
        }

        columns.forEach((column, index) => {
            column.menuTabs = ['filterMenuTab', 'generalMenuTab'];
            if (column.filter) {
                if (column.filter != FilterType.date || (column.filter == FilterType.date && this.options.usingTopCalendar)) column.floatingFilterComponentParams = { suppressFilterButton: true };
                switch (column.filter) {
                    case FilterType.number:
                        column.floatingFilterComponent = 'customNumberFloatingFilter';
                        break;
                    //להוסיף פה אפשרות של pipe ב
                    //בשביל תאריך עסקה אחרון בטבלת עסקאות
                    case FilterType.date:
                        // column.floatingFilterComponent = 'customNumberFloatingFilter';
                        // break;
                        //cellRenderer: (data) => {
                        //     return moment(data.createdAt).format('MM/DD/YYYY HH:mm')
                        // }
                        break;
                    case FilterType.customDate:
                        // column.floatingFilterComponent = 'customDateFloatingFilter';
                        break;
                    case FilterType.list:
                        column.floatingFilterComponent = 'listColumnFilter';
                        break;
                    default:
                        break;
                }
            }
            // if(column.compareType)
            //    {
            //     var dateFilterComponent = this.gridApi.getFilterInstance(column.field)!;
            //     dateFilterComponent.setModel({
            //       type: column.compareType,                 
            //     });
            //    }
            if (column.style) {
                //this.setColumnStyle();
                switch (column.style) {
                    case CellStyle.date:
                        column.cellStyle = { background: 'red' }
                        break;
                    case CellStyle.button:
                        column.cellStyle = {
                            padding: ' 0 10px',
                            'border': '1px solid #154ba3', 'border-radius': '5px'
                        }
                        break;

                    default:
                        break;
                }
            }
            column.valueGetter = column.valueCondition
            column.colId = column.field; //to multiSort
            column.valueGetter = column.valueCondition;
            column.headerComponent = "customColumnHeader";
            column.headerComponentParams = {
                allowSort: column.sortable,
                displayCloseIcon: !column.lock,
                editable: this.options.editSettings.allowEdit == true && column.disableEditing != true,
            }

            this.setColumnType(column, index)
            this.setColumnValue(column)
            this.setColumnStyle(column);
            this.setColumnFilter(column);
            this.setColumnSort(column);
            this.setColumnVisibility(column);
            this.setColumnEvents(column);
            this.setColumnEditable(column);
            this.setColumnTextWrap(column);
        });
        return this.setSelectionColumn(columns);
    }
    setColumnValue(column: any) {
        if (column.value == ValueType.NoLimit)
            column.valueGetter = (params) => {
                return params.data[column.colId] == column.customValueParams.valueToFormat ?
                    this.getTranslation('OBLIGATIONS.NO_LIMIT') :
                    params.data[column.colId]
            }
    }
    private setColumnEditable(column: any) {
        if (this.options.editSettings.allowEdit == true && column.disableEditing != true && column.type != ColumnType.Button) {
            column.cellRenderer = "editCellRenderer";
            // var list=column.cellRendererParams?.list;
            var list = column.customFilterParams?.values
            column.cellRendererParams = {
                validators: column.validators,
                context: {
                    formGroup: this.formGroup,
                    formArray: this.formArray,
                    onSaveChanges: this.options.editSettings.onSaveChanges,
                    list: list,
                    type: column.type
                }

            }
        }

    }
    private setColumnTextWrap(column: any) {
        if (column.wrapText) {
            column.wrapText = true;
            column.autoHeight = true;
        }
    }
    private setColumnType(column: any, index) {
        if (index == 0 && this.options.masterDetail == true)
            column.cellRenderer = 'agGroupCellRenderer'
        if (column.type) {
            switch (column.type) {
                case ColumnType.Button:
                    column.cellRenderer = 'customBtnClick',
                        column.cellRendererParams = {
                            clicked: column.customColumnParams.clickCallback,
                            //classCondition:column.customColumnParams.classCondition,
                            context: column.customColumnParams.text,
                            icon: column.customColumnParams.icon,
                            tooltip: column.customColumnParams.tooltip
                        }
                    break;

                case ColumnType.Boolean:
                    column.cellRenderer = params => params.value ? this.getTranslation('GENERAL.YES') : this.getTranslation('GENERAL.NO')
                    break;

                case ColumnType.Distance:
                    column.cellRenderer = params => {
                        var [x, y] = params.data?.gps?.split(',') ?? [0, 0];
                        if (!x && !y) return this.getTranslation('INFO_MESSAGES.LOCATIN_NOT_DEFINED');
                        return (Number)(column.customColumnParams.calc(x, y));
                    }
                    break;

                case ColumnType.Link:
                    column.cellRenderer = params => {
                        return !params.data ? "" : (!params.value ? '' : ("<a href=" + column.customColumnParams.createLink(params) + " target='_blank' class='hover-underline'>" + params.value + "</a>"));
                    }
                    break;

                case ColumnType.Model:
                    column.cellRenderer = params => {
                        let res = column.customColumnParams.type?.filter(item => item[column.customColumnParams.key] == params.value)[0]?.[column.customColumnParams.val];
                        if (res) {
                            res = this.getTranslation(column.customColumnParams.translatePrefix + res);
                        }
                        return res;
                    }
                    break;

                case ColumnType.Combined:
                    column.cellRenderer = params => {
                        let data = column.customColumnParams;
                        let res = data.source?.find(item => item[data.id] == params.data?.[data.compareTo])?.[data.field];
                        if (typeof (res) === 'number' && data.isNumber)
                            res = formatNumber(res, this.locale, '1.2-2');
                        return data.source ? (res ?? data.default ?? params.data[data.defaultField]) : '<i class="pi pi-spin pi-spinner"></i>';
                    }
                    break;

                case ColumnType.Checkbox:
                    column.cellRendererParams = column.customColumnParams;
                    column.cellRenderer = params => {
                        var input = document.createElement('input');
                        input.type = "checkbox";
                        input.checked = (params.data[(params.colDef.colId)[0].toLowerCase() + (params.colDef.colId).slice(1)]) == params.checked;
                        input.disabled = params.disable(params);
                        input.addEventListener('change', function () {
                            if (params.onClick) params.onClick(params);
                            else {
                                if (params.value == params.checked && params.onCheck) params.onCheck(params);
                                else if (params.value != params.checked && params.onUnCheck) params.onUnCheck(params);
                            }
                        });
                        return input;
                        // return `<input type='checkbox' ${params.value == params.checked ? 'checked' : '' } (change)="${params.onCheck()}" />`;
                    }
                    break;
                case ColumnType.CheckboxIcon:
                    column.cellRenderer = 'customCheckBoxIcon',
                        column.cellRendererParams = {
                            clicked: column.customColumnParams.clickCallback,
                            falseIcon: column.customColumnParams.falseIcon,
                            trueIcon: column.customColumnParams.trueIcon
                        }
                    break;
                case ColumnType.Enum:
                    column.cellRenderer = 'customEnumTranslateColumn'
                    break;
                case ColumnType.Pipe:
                    column.cellRenderer = "customPipeColumn";
                    column.cellRendererParams = column.customColumnParams
                    break;
                case ColumnType.Template:
                    column.cellRenderer = "customTemplateColumn";
                    column.cellRendererParams = column.customColumnParams
                    break;

            }
        }
    }
    private setColumnStyle(column: any) {
        let classes = "";
        let styles = {};
        column.style?.forEach(prop => {
            if (typeof prop === "string") classes += " " + prop;
            else styles = { ...styles, ...prop };
        });
        column.cellStyle = styles;
        column.cellClass = classes;
    }

    private setColumnFilter(column: any) {
        column.filterParams = column.customFilterParams;

    }
    private setColumnSort(column: any) {
        column.sortable = column.sortable;
    }

    private setColumnVisibility(column: any) {
        if (column.hideColumn) {
            column.hide = column.hideColumn(column);
        }
    }

    private setColumnEvents(column: any) {
        if (column.onClick) {
            column.onCellClicked = column.onClick;
        }
    }

    private setSaveAndCancelColumns(columns: any) {
        let saveColumn = columns?.find(c => c.field == "*");
        let cancelColumn = columns?.find(c => c.field == "&");
        if (this.saveChangesButton && this.options?.editSettings?.allowAdd == true) {
            if (!saveColumn)
                columns = [...columns, {
                    ...defualtColumnOptions,
                    field: '*',
                    headerName: '',
                    type: ColumnType.Template,
                    customColumnParams: new TemplateParams(this.saveChangesButton),
                    cellRenderer: "customTemplateColumn",
                    cellRendererParams: new TemplateParams(this.saveChangesButton),
                    width: 80,
                    minWidth: 80,
                    cellStyle: {
                        padding: '10px 16px',
                    }
                },];
            if (!cancelColumn)
                columns = [...columns, {
                    ...defualtColumnOptions,
                    field: '&',
                    headerName: '',
                    type: ColumnType.Template,
                    customColumnParams: new TemplateParams(this.cancelChangesButton),
                    cellRenderer: "customTemplateColumn",
                    cellRendererParams: new TemplateParams(this.cancelChangesButton),
                    width: 80,
                    minWidth: 80,

                    cellStyle: {
                        padding: '10px 16px',
                    }
                },];
        }

        return columns;
    }
    private setSelectionColumn(columns: any) {

        if (!this.options.showSelectionColumn || this.options.rowSelectionType != RowSelectionType.multiple) return columns.filter(c => c.headerName != "#");
        let selectionColumn = columns.find(c => c.headerName == "#");
        if (!selectionColumn) {
            columns = [{
                field: '',
                headerName: '#',
                editable: false,
                checkboxSelection: this.options.rowSelectionType === RowSelectionType.multiple,
                headerComponent: "customFirstColumnHeader",
                headerComponentParams: {
                    filter: () => this.handlingRowFilter(),
                    showFilter: false,
                    menuIcon: 'ag-icon ag-icon-menu',
                    getGridObject: () => this.agGrid,
                    showSelectAll: this.options.showSelectAll,
                },
                cellRenderer: 'loadingRenderer',
                width: 60,
                sortable: false,
                menuTabs: ['columnsMenuTab'],
                pinned: 'right',
                // lockPinned: true,
                // cellClass: 'lock-pinned',
                lockVisible: true,
                lockPosition: 'right',
                cellStyle: {
                    background: "#f8f8f8",
                },
                columnsMenuParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true,
                }
            }, ...columns];
        }
        return columns;
    }
    private setGridSidebar(options: any) {

        // Custom tool panels:
        if (options.customToolPanels.length) {
            this.gridOptions.customToolPanels = options.customToolPanels;
        }

        // Set all tool panels by settings:
        let allowedToolPanels = [
            options.displaySidebarColumns ? "columns" : null,
            options.displaySidebarFilter ? "filters" : null,
            ...options.customToolPanels.map(t => t.id)
        ];
        this.sideBar = allowedToolPanels.filter(s => s).length ? { ...this.sideBar, toolPanels: globalSideBar.toolPanels.filter(t => allowedToolPanels.includes(t.id)) } : null
    }
    private setGridType() {
        switch (this.options.gridType) {
            case DataLoadType.server:
                this.setGridVirtualScrolling();
                break;
        }
    }
    private handlingRowFilter() {
        switch (this.filterMode) {
            case FilterMode.hidden:
                this.filterMode = FilterMode.show;
                var columnDefs = this.agGrid.api.getColumnDefs();
                columnDefs.forEach(function (colDef, index) {
                    let fcolDef: any = colDef
                    if (colDef.headerName == "#") {
                        fcolDef.headerComponentParams.showFilter = true;
                    }
                    fcolDef.floatingFilter = true;
                    colDef = fcolDef
                });
                this.agGrid.api.setColumnDefs(columnDefs);
                break;
            case FilterMode.show:
                this.filterMode = FilterMode.hidden;
                var columnDefs = this.agGrid.api.getColumnDefs();
                columnDefs.forEach(function (colDef, index) {
                    let fcolDef: any = colDef
                    if (colDef.headerName == "#") {
                        fcolDef.headerComponentParams.showFilter = false;
                    }
                    fcolDef.floatingFilter = false;
                    colDef = fcolDef
                });
                this.agGrid.api.setColumnDefs(columnDefs);
                this.gridApi.setFilterModel(null);
                break;
        }
        // this.buttons.filter(b => b.buttonType == ButtonType.FilterDisplay)[0].label = this.setFilterButtonLabel();
    }
    private setFilterButtonLabel() {
        switch (this.filterMode) {
            case FilterMode.hidden:
                return this.getTranslation('GENERAL.SHOW_FILTER')
            case FilterMode.show:
                return this.getTranslation('GENERAL.HIDDEN_FILTER')
        }
    }
    private setDefualtGridOptions() {
        this._options = defualtGridOptions;
        // if (!this.hasDisplayFilter())
        //     this._options.buttons.push({ buttonType: ButtonType.Custom, label: this.getTranslation('GENERAL.SHOW_FILTER'), icon: null, displayOptions: ButtonDisplayOption.always, enableOptions: ButtonEnableOption.always, onClick: this.handlingRowFilter.bind(this), tooltip: "" });
        this.setGridType();
        this.setCommonOptions();
    }
    private setGridVirtualScrolling() {
        if (this.options?.editSettings.allowEdit == true)
            this.gridOptions.rowHeight = 43;
        this.gridOptions.rowModelType = 'serverSide';
        this.gridOptions.serverSideStoreType = this.options.serverSideStoreType;
        this.gridOptions.suppressFocusAfterRefresh = true;
        this.gridOptions.maxConcurrentDatasourceRequests = 10;
        // this.gridOptions.blockLoadDebounceMillis = 500;//wait time in miliseconds before load a block (using for fast scrolling to avoid load all pages, just the end set page)
        this.gridOptions.cacheBlockSize = this.options.cacheBlockSize;
        this.gridOptions.maxBlocksInCache = 5

        if (this.options.masterDetail) {
            this.gridOptions.masterDetail = true;
            this.gridOptions.detailCellRenderer = "masterDetailsRendererComponent";
            this.gridOptions.detailCellRendererParams = { value: this.options.masterDetailsComponent };
            if (this.options.masterDetailsData) {
                this.gridOptions.detailCellRendererParams.data = this.options.masterDetailsData();
            }


            this.gridOptions.detailRowHeight = this.options.detailRowHeight || 800;
            //זה לא עובד לפי מה שהבנתי צריך לעדכן לגירסה 26 
            // this.gridOptions.detailRowAutoHeight=true;

        }
        this.gridOptions.noRowsOverlayComponent = CustomNoRowsOverlay,
            this.gridOptions.noRowsOverlayComponentParams = {
                noRowsMessageFunc: () => this.options.noRowsOverlayComponentMessage != null ? this.options.noRowsOverlayComponentMessage :
                    this.sumHeaderText ? this.getTranslation('INFO_MESSAGES.NODATAFOUND') + this.sumHeaderText : this.getTranslation('INFO_MESSAGES.EMPTY_RESULTS'),
            }
    }
    private setGridLocalization() {
        // this.subscriptions.add(this.store.select(selecTranslationInCommonState).subscribe(language => {
        //     switch (language) {
        //         case Localization.He:
        this.gridOptions.localeText = AG_GRID_LOCALE_HE;
        this.gridOptions.enableRtl = true;
        //             break;
        //         case Localization.En:
        //             this.gridOptions.localeText = AG_GRID_LOCALE_EN;
        //             this.gridOptions.enableRtl = false;
        //             break;
        //     }
        // }));
    }
    private setCommonOptions() {
        this.gridOptions.suppressPropertyNamesCheck = true;
        this.gridOptions.multiSortKey = this.options.multiSort ? 'ctrl' : null;
        this.gridOptions.onRowSelected = this.rowSelected.bind(this);
        this.gridOptions.onCellContextMenu = this.selectRow.bind(this);
        this.gridOptions.onCellEditingStopped = this.onCellEditingStopped.bind(this);
        this.gridOptions.onRowEditingStoppedd = this.onRowEditingStopped.bind(this);
        //this.gridOptions.onCellDoubleClicked = this.options.editSettings.allowEdit == true ? (params) => this.dbClickCellForEdit(params) : null;
        this.gridOptions.onRowDoubleClicked = this.rowDoubleClicked.bind(this);
        this.gridOptions.onRowClicked = this.rowClicked.bind(this);
        this.gridOptions.rowSelection = RowSelectionType[this.options.rowSelectionType].toString();
        this.gridOptions.components = {
            loadingRenderer: (params) => {
                if (!params.data) {
                    return "<img src=\"assets\\images\\loading.gif\">";
                }
            },


        };
        this.gridOptions.defaultColDef = {
            resizable: true,
            width: 200,
            // filter:true,
            floatingFilter: false,//filter under title
            sortable: true,
        }
        this.gridOptions.frameworkComponents = {
            /** Filters */
            customNumberFloatingFilter: NumberFloatingFilterComponent,
            customDateFloatingFilter: CustomDateFloatFilterComponent,
            listColumnFilter: CustomListFilterComponent,

            /** Cell renderers */
            customEnumTranslateColumn: EnumTranslateColumnComponent,
            customPipeColumn: PipeRendererComponent,
            customTemplateColumn: TemplateRendererComponent,
            myDetailCellRenderer: DetailCellRenderer,
            editCellRenderer: FormGroupCellRenderer,

            /** Templates */
            customFirstColumnHeader: FirstColumnTemplateComponent,
            customColumnHeader: ColumnHeaderTemplateComponent,
            customBtnClick: BtnCellRenderer,
            customCheckBoxIcon: CheckBoxIconRenderer,
            agDateInput: CustomDateFilterComponent,

            /** Sidebars */
            customQuickScanToolPanel: QuickScanToolPanel
        };

        if (this.options && this.options.masterDetail)
            this.gridOptions.frameworkComponents.masterDetailsRendererComponent = MasterDetailsRendererComponent;
        let addNewItemButton: CustomButton = {
            ...newButton,
            label: this.options?.editSettings?.addSettings?.buttonLabel ? this.options?.editSettings?.addSettings?.buttonLabel : null,
            onClick: () => {
                this.addRow(undefined, this.options?.editSettings?.addSettings?.row ? this.options?.editSettings?.addSettings?.row : {})
            }
        }
        if (this.options.editSettings.allowAdd == true) {
            if (this.buttons.filter(x => x.label == addNewItemButton.label).length == 0)
                this.buttons.push(addNewItemButton);
            this._columns = this.setSaveAndCancelColumns(this.columns);
        }

    }

    addRow(index: number | undefined, row: any = {}) {
        this.changeEditType();
        setTimeout(() => {
            //add row to table
            const itemsToAdd = [];
            itemsToAdd.push(row);
            const tx = {
                addIndex: 0,
                add: itemsToAdd,
            };
            this.gridApi.applyServerSideTransaction(tx);

            //set row for edit
            var colKey = this.agGrid.api.getColumnDefs()[0]['field'];
            this.gridApi.startEditingCell({
                rowIndex: 0,
                colKey: colKey,
            });
            this.gridApi.setFocusedCell(0, colKey);
        }, 500);

    }

    saveChanges() {
        this.gridApi.stopEditing();
        this.options.editSettings.onAddRow();
        this.changeEditType();
    }

    cancelChanges() {
        this.gridApi.stopEditing();
        this.refreshData();
        this.changeEditType();
    }
    //change edit type - if is new - editType= 'fullRow' and if edit exist row - set for edit only spesific cell
    private changeEditType() {
        if (this.gridOptions.editType == 'fullRow') {
            const columnDefs: ColDef[] = this.agGrid.api.getColumnDefs();
            columnDefs.forEach(function (colDef: any) {
                colDef.editable = colDef.editable == true ? null : colDef.editable;
            });
            this.gridApi.setColumnDefs(columnDefs);
            this.gridOptions.editType = '';
            this.refreshGridOptions();
        }
        else {
            this.gridOptions.editType = 'fullRow';
            this.refreshGridOptions();
            var columnDefs = this.agGrid.api.getColumnDefs();
            columnDefs.forEach(function (colDef: any) {
                colDef.editable = colDef.editable == false ? false : true;
            });
            this.gridApi.setColumnDefs(columnDefs);

        }
    }

    private setLoadDataParameters(params) {
        //let dropdownfilter=`${this.options.dropdownfilter.filterName}:${this.options.dropdownfilter.selectedData}`
        let getListModel: loadDataParameter = {
            filter: [],
            sort: [],
            Pagination: {
                Limit: this.gridOptions.cacheBlockSize,
                PageIndex: params.startRow
            },
            freeSearchText: this.freeSearchText,
            searchCreditNumberText: this.searchCreditNumberText,
            status: this.options?.status?.selectedStatus,
        }
        let fieldType: FieldType;
        Object.keys(params.filterModel).forEach(filter => {
            let operator: CompareType;
            let filterValue;
            let operatorValue: string;
            switch (params.filterModel[filter].filterType) {
                case "date":
                    if (params.filterModel[filter].type == "inRange") {
                        operator = CompareType.between;
                        fieldType = FieldType.dateRange;
                        filterValue = [params.filterModel[filter].dateFrom, params.filterModel[filter].dateTo];
                    }
                    else {
                        operator = CompareType.equals;
                        filterValue = [params.filterModel[filter].dateFrom];
                        fieldType = FieldType.date
                    }
                    break;
                case "dateRange": // from CustomDateFilter
                    filterValue = [params.filterModel[filter].minDate, params.filterModel[filter].maxDate];
                    operator = CompareType.between;
                    fieldType = FieldType.dateRange;
                    break;
                case "set":
                    let value = params.filterModel[filter].values;
                    if (Array.isArray(value))
                        filterValue = value;
                    else
                        filterValue = [value[0]];
                    fieldType = FieldType.enum;
                    operator = CompareType.equals;
                    break;

                default: //"text","number"
                    if (!params.filterModel[filter].filter.push) {
                        filterValue = [params.filterModel[filter].filter];
                    }
                    //numPayment type
                    var column = this.columns.filter((x) => { return x.colId == filter })[0];
                    if (column?.value == ValueType.NoLimit) {
                        if (this.getTranslation('GENERAL.NO_LIMITED').includes(filterValue[0]))
                            filterValue[0] = column.customValueParams.filterValue;
                        else if (isNaN(filterValue[0]))
                            filterValue[0] = null;
                        operatorValue = CompareType[CompareType.equals];
                    }
                    fieldType = FieldType.string

                    break;
            }
            operatorValue = operatorValue ? operatorValue : params.filterModel[filter].type;
            getListModel.filter.push({ Operator: !operatorValue ? operator : CompareType[operatorValue], Field: filter, Value: filterValue, FieldType: fieldType })
        });
        params.sortModel.forEach(sort => {
            let sortValue: string = sort.sort;
            getListModel.sort.push({ Field: sort.colId, SortType: SortType[sortValue] })
        });
        this.loadDataParameters = getListModel;
    }

    private rowDoubleClicked(row) {
        if (row.event) {
            row.event.preventDefault();
            if (window.getSelection) {
                var sel = window.getSelection();
                sel.removeAllRanges();
            }
        }
        this.rowDoubleClickedEvent.emit(row.data);
        this.rowDoubleClickedWithRowData.emit(row);
        return false;
    }

    private rowClicked(row) {
        this.rowClickedEvent.emit(row.data);

        // Detect a click:
        // this.aClickAccurred ++;

        // setTimeout(() => {
        //     // If this is a double click - do not fire single click actions:
        //     if(!this.aClickAccurred || this.aClickAccurred == 2) {
        //         this.aClickAccurred = 0;
        //         return;
        //     }

        //     // Reset clicks:
        //     this.aClickAccurred = 0;

        //     // Check if another action is set to click event:
        //     let rowClickSubscribers = (this.rowClickedEvent as Subject<any>).observers;

        //     // If parent component set an action on click - fire parent component action:
        //     if(rowClickSubscribers.length) {

        //     }
        //     // Else - open row details popup:
        //     else {
        //         this.viewRowDetails(row);
        //     }
        // }, 180)
    }

    private rowSelected(row) {
        if (!this.options.rowSelectionCondition(row)) {
            this.gridOptions.api.deselectAll();
            return;
        };

        if (this.options.rowSelectionType == RowSelectionType.multiple) {
            this.selectedData = this.agGrid.api?.getSelectedRows();
        }
        else {
            this.selectedData = this.agGrid.api?.getSelectedRows()[0];
        }
    }

    private selectRow(event) {
        if (!event)
            return;
        if (!event.node) {
            this.selectRowById(event.data?.id);
            return;
        }
        this.gridOptions.api.deselectAll();
        this.gridOptions.api.forEachNode(node => {
            if (event.node.rowIndex == node.rowIndex) node.setSelected(true)
        })
    }

    private getTranslation(translationWord, parameter?) {
        return this.translate.instant(translationWord, parameter)
    }

    private viewRowDetails(row: any, more: boolean): void {
        this.moreDetails = more;
        if (more)
            this.options.getMoreDetails(row).subscribe(res => {
                if (res.succeeded) {
                    this.detials = Object.keys(res.entity).map(key => ({
                        name: key,
                        title: this.getTranslation(`MORE_DETAILS.${key.toUpperCase()}`),
                        value: res.entity[key]
                    }));
                }
                else
                    this.detials = [];
                this.toggleDisplayRowDetails();
            });
        else this.toggleDisplayRowDetails();
    }

    private toggleDisplayRowDetails(): void {
        this.displayRowDetails = !this.displayRowDetails;
        this._cdr.markForCheck();
    }

    closeDisplayRowDetails() {
        this.displayRowDetails = false;
        this.moreDetails = false;
        this._cdr.markForCheck();
    }

    getRowDetailsTitle() {
        return this.options.rowDetailsTitle ?? "שורה";
    }
    detials: any[] = [];
    getDisplayKeysFromGridColumnsDefinition(): any[] {
        if (this.moreDetails) {
            return this.detials; // החזר מערך ריק או ערך אחר בזמן שהנתונים נטענים
        }
        else {
            let tableColumns = this.gridColumnApi.getColumnState()?.filter(col => col.hide == false && col.colId != "0" && !this.options?.rowDetailsHiddenFields.includes(col.colId));
            return tableColumns.map(tc => {
                let col = this.columns?.find(col => col.colId == tc.colId)
                return ({ name: col?.field, title: col?.headerName, src: col })
            });
        }
    }

    saveState(event: any) {
        // If table is configured to not save table state - return:
        if (!this.options.saveTableState) return;

        // Ignore changes which did not come from tool panel (like statuses, etc.), and changes when not on main tab:
        if (!["toolPanelUi", "uiColumnDragged", "contextMenu", "api", "uiColumnSorted"].includes(event.source)) return;

        // get host component name
        let hostComponent = this.getParent(this.elRef.nativeElement)?.localName;
        // get current stored table columns state:
        var storedColumnsState = JSON.parse(localStorage.getItem("agState")) ?? {};
        // set current table state:
        storedColumnsState[hostComponent] = this.gridColumnApi.getColumnState();
        var visibleColumns = storedColumnsState[hostComponent].filter((col) => (col.hide == false))
        if (visibleColumns.length < this.options.minimumVisibleColumns) {
            //this is the checkbox near column name
            if (event.column)
                storedColumnsState[hostComponent].find((col) => col.colId == event.column.colId).hide = false;
            //this is the checkbox near "All"
            else
                storedColumnsState[hostComponent].filter((col) => this.options.VisibleColumnsNames.includes(col.colId)).map((col) => col.hide = false);
        }
        //return to grid
        this.gridColumnApi.applyColumnState({
            state: storedColumnsState[hostComponent],//this.getState(),
            applyOrder: true,
        });


        // Save tables columns state to local storage:
        localStorage.setItem("agState", JSON.stringify(storedColumnsState));
    }
    getState() {
        let hostComponent = this.getParent(this.elRef.nativeElement)?.localName;
        var state = localStorage.getItem("agState");
        return JSON.parse(state)?.[hostComponent];
    }

    getParent(elementRef) {
        if (elementRef && elementRef.parentNode) {
            let localName = elementRef.parentNode.localName;
            if (localName == 'div' || localName == 'p-dialog')
                return this.getParent(elementRef.parentNode);
            return elementRef.parentNode;
        }
        return elementRef;
    }

    onBodyScroll(event) {
        // if(event.type == "horizontal"){
        //     this.gridApi.refreshCells();
        // }
    }

    listenToSidebarOpen() {
        this.gridApi.addEventListener("toolPanelVisibleChanged", this.reloadToolPanel.bind(this));
    }


    reloadToolPanel(event) {
        if (!this.toolPanelAlreadyOpened && event.source == "quickScan") {
            this.toolPanelAlreadyOpened = true;
            this.quickScanOpened.emit();
        }
    }

    onCellEditingStopped(event) {
        this.cellEditingStopped.emit({
            ...event,
            errors: this.checkCellValidators(event.value, event.colDef.validators)
        });
    }
    onRowEditingStopped(event) {
        this.rowEditingStopped.emit(event);
    }
    stopEditing() {
        this.gridApi.stopEditing();
    }

    checkCellValidators(value: any, validators: [any]) {
        let control = new FormControl(value, validators);
        control.markAllAsTouched();
        return control.errors;
    }
    onMobile() {
        return window.innerWidth < 790;
    }
    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(event: KeyboardEvent) {

        if (this.totalCount == 1 && this.selectedData)
            this.rowDoubleClickedEvent.emit(this.selectedData);
    }
}

