import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

import { ButtonModule } from 'primeng/button';
import { LocalizationModule } from 'shared/src/localization';



@NgModule({
  declarations: [
   SignaturePadComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    LocalizationModule,
    ButtonModule,

  ],
  exports: [
    SignaturePadComponent
    ]
})
export class SignatureModule { }
